<template>
  <div class="legends">
    <div v-for="(label, index) in labels" :key="index" class="legend-item">
      <span :style="{ backgroundColor: colors[index] }" class="legend-color"></span>
        {{ label }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    labels: Array,
    colors: Array
  }
};
</script>
<style scoped>
 .legend-item {
   display: flex;
   align-items: center;
   margin-bottom: 8px;
 }

 .legend-color {
   width: 16px;
   height: 16px;
   margin-right: 8px;
 }
</style>
