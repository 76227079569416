<template>
  <div ref="full_statistics">
    <v-card class="mb-6">
      <div class="d-flex justify-space-between align-center">
        <v-card-title>{{ t('Estadísticas') }}</v-card-title>
        <v-btn
          class="no-print mr-2"
          color="primary"
          @click="printFullSection()"
        >
          {{ $t('Imprimir') }}
          <v-icon class="ml-2">
            {{ icons.mdiPrinter }}
          </v-icon>
        </v-btn>
      </div>
    </v-card>

    <div
      ref="evaluation_statistics"
      class="evaluation-statistics-container"
    >
      <v-card>
        <v-divider class="mt-4"></v-divider>

        <v-card-text class="d-flex align-center flex-wrap pb-0 mb-2">
          <div class="statistics-subheader">
            <h2>{{ $t('Por evaluación') }}</h2>
            <span>Total: {{ evaluationStatistics.total_evaluations }}</span>
          </div>
          <v-spacer></v-spacer>
          <div class="no-print">
            <v-btn
              color="primary"
              @click="printEvaluationSection()"
            >
              {{ $t('Imprimir sección') }}
              <v-icon class="ml-2">
                {{ icons.mdiPrinter }}
              </v-icon>
            </v-btn>
          </div>
        </v-card-text>

        <div>
          <div
            v-for="(value, key) in evaluationStatistics"
            :key="key"
            class="mt-2"
          >
            <div v-if="key !== 'total_evaluations'">
              <v-row class="eval-stat-section mb-7">
                <v-col
                  cols="3"
                  md="3"
                  class="pl-8 pr-8 print-labels d-flex flex-column justify-center"
                >
                  <div>
                    <h4>
                      {{ $t(sectionParser[key.split('_')[2]]) }}
                    </h4>
                  </div>

                  <div>
                    <div
                      v-for="(stat, idx, k) in value"
                      :key="idx"
                      class="my-2"
                    >
                      <!-- legend-item y legend-color son clases que aplican solo al modo print -->
                      <div v-if="key === 'evaluations_by_continent'" class="legend-item">
                        <span class="legend-color" :class="`legend-${getLegendIndex(k)}`"></span>
                        <strong>{{ continentParser(idx) }}:</strong> {{ stat }}
                      </div>
                      <div v-if="key === 'evaluations_by_country'" class="legend-item">
                        <span class="legend-color" :class="`legend-${getLegendIndex(idx)}`"></span>
                        <strong>{{ countryParser[stat.school__region__country__name] }}:</strong> {{ stat.total }}
                      </div>
                      <div v-if="key === 'evaluations_by_region'">
                        <strong>{{ $t('País') }}: {{ countryParser[idx] }}</strong>
                        <div
                          v-for="(region, regionIdx) in stat"
                          :key="regionIdx"
                          class="my-2"
                        >
                          <div class="legend-item">
                            <span class="legend-color" :class="`legend-${getLegendIndex(region.index)}`"></span>
                            <div>{{ region.region }}: {{ region.total }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="5"
                  md="5"
                >
                  <div>
                    <vue-apex-charts v-if="chartOptions.evaluationsCharts[`${key.split('_')[2]}Chart`]"
                      :ref="`evaluations_donut_chart_${key.split('_')[2]}`"
                      class="pt-8 charts"
                      type="pie"
                      height="350"
                      :options="chartOptions.evaluationsCharts[`${key.split('_')[2]}Chart`]"
                      :series="chartSeries.evaluationsSeries[`${key.split('_')[2]}Chart`]"
                      :name="`chart_${key.split('_')[2]}`"
                    />
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  md="4"
                  class="d-flex flex-column justify-center no-print"
                >
                  <CustomApexLegends v-if="chartOptions.evaluationsCharts[`${key.split('_')[2]}Chart`]"
                    :labels="chartOptions.evaluationsCharts[`${key.split('_')[2]}Chart`].labels"
                    :colors="chartOptions.evaluationsCharts[`${key.split('_')[2]}Chart`].colors"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-card>
    </div>

    <div ref="school_statistics">
      <v-card>
        <v-divider class="mt-4"></v-divider>

        <v-card-text class="d-flex align-center flex-wrap pb-0 mt-2">
          <div class="statistics-subheader">
            <h2>{{ $t('Por escuela') }}</h2>
            <span>Total: {{ schoolStatistics.total_schools }}</span>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              class="no-print"
              color="primary"
              @click="printSchoolSection()"
            >
              {{ $t('Imprimir sección') }}
              <v-icon class="ml-2">
                {{ icons.mdiPrinter }}
              </v-icon>
            </v-btn>
          </div>
        </v-card-text>

        <div>
          <div
            v-for="(value, key) in schoolStatistics"
            :key="key"
            class="mt-2"
          >
            <div v-if="key !== 'total_schools'">
              <v-row class="school-stat-section mb-7">
                <v-col
                  cols="3"
                  md="3"
                  class="pl-8 pr-8 print-labels d-flex flex-column justify-center"
                >
                  <div>
                    <h4>
                      {{ $t(sectionParser[key.split('_')[2]]) }}
                    </h4>
                  </div>

                  <div>
                    <div
                      v-for="(stat, idx, k) in value"
                      :key="idx"
                      class="my-2"
                    >
                      <div v-if="key === 'schools_by_continent'" class="legend-item">
                      <span class="legend-color" :class="`legend-${getLegendIndex(k)}`"></span>
                        <strong>{{ continentParser(idx) }}:</strong> {{ stat }}
                      </div>
                      <div v-if="key === 'schools_by_country'" class="legend-item">
                      <span class="legend-color" :class="`legend-${getLegendIndex(idx)}`"></span>
                        <strong>{{ countryParser[stat.region__country__name] }}:</strong> {{ stat.total }}
                      </div>
                      <div v-if="key === 'schools_by_region'">
                        <strong>{{ $t('País') }}: {{ countryParser[idx] }}</strong>
                        <div
                          v-for="(region, regionIdx) in stat"
                          :key="regionIdx"
                          class="my-2"
                        >
                          <div class="legend-item">
                            <span class="legend-color" :class="`legend-${getLegendIndex(region.index)}`"></span>
                            <div>{{ region.region }}: {{ region.total }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="5"
                  md="5"
                >
                  <div>
                    <vue-apex-charts v-if="chartOptions.schoolsCharts[`${key.split('_')[2]}Chart`]"
                      :ref="`schools_donut_chart_${key.split('_')[2]}`"
                      class="pt-8 charts"
                      type="pie"
                      height="350"
                      :options="chartOptions.schoolsCharts[`${key.split('_')[2]}Chart`]"
                      :series="chartSeries.schoolsSeries[`${key.split('_')[2]}Chart`]"
                      :name="`school_chart_${key.split('_')[2]}`"
                    />
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  md="4"
                  class="d-flex flex-column justify-center no-print"
                >
                  <CustomApexLegends v-if="chartOptions.schoolsCharts[`${key.split('_')[2]}Chart`]"
                    :labels="chartOptions.schoolsCharts[`${key.split('_')[2]}Chart`].labels"
                    :colors="chartOptions.schoolsCharts[`${key.split('_')[2]}Chart`].colors"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { Printd } from 'printd'
import VueApexCharts from 'vue-apexcharts'
import {
  mdiPrinter,
} from '@mdi/js'
import countriesParserMixin from '@/utils/countriesParserMixin'
import CustomApexLegends from '@/components/CustomApexLegends.vue'

export default {
  components: {
    VueApexCharts,
    CustomApexLegends,
  },
  mixins: [countriesParserMixin],
  data: () => ({
    evaluationLoading: false,
    schoolLoading: false,
    evaluationStatistics: {},
    schoolStatistics: {},
    chartOptions: {
      evaluationsCharts: {
        countryChart: {
          chart: {
            fontFamily: 'AvenirNext, Inter, sans-serif',
          },
          colors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D', '#319ee9'],
          legend: {
            show: false,
          },
          dataLabels: {
            style: {
              colors: ['black'],
              fontSize: '17px',
            },
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: 56,
              },
              customScale: 0.85,
            },
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              }
            },
          }],
          labels: [],
        },
        regionChart: {
          chart: {
            fontFamily: 'AvenirNext, Inter, sans-serif',
          },
          colors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D', '#319ee9'],
          legend: {
            show: false,
          },
          dataLabels: {
            style: {
              colors: ['black'],
              fontSize: '17px',
            },
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: 56,
              },
              customScale: 0.85,
            },
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          }],
          labels: [],
        },
        continentChart: {
          chart: {
            fontFamily: 'AvenirNext, Inter, sans-serif',
          },
          colors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D', '#319ee9'],
          legend: {
            show: false,
          },
          dataLabels: {
            style: {
              colors: ['black'],
              fontSize: '17px',
            },
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: 56,
              },
              customScale: 0.85,
            },
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },

            },
          }],
          labels: [],
        },
      },
      schoolsCharts: {
        countryChart: {
          chart: {
            fontFamily: 'AvenirNext, Inter, sans-serif',
          },
          colors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D', '#319ee9'],
          legend: {
            show: false,
          },
          dataLabels: {
            style: {
              colors: ['black'],
              fontSize: '17px',
            },
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: 56,
              },
              customScale: 0.85,
            },
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          }],
        },
        regionChart: {
          chart: {
            fontFamily: 'AvenirNext, Inter, sans-serif',
            width: 500,
          },
          colors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D', '#319ee9'],
          legend: {
            show: false,
          },
          dataLabels: {
            style: {
              colors: ['black'],
              fontSize: '17px',
            },
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: 56,
              },
              customScale: 0.85,
            },
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          }],
        },
        continentChart: {
          chart: {
            fontFamily: 'AvenirNext, Inter, sans-serif',
          },
          colors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D', '#319ee9'],
          legend: {
            show: false,
          },
          dataLabels: {
            style: {
              colors: ['black'],
              fontSize: '17px',
            },
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: 56,
              },
              customScale: 0.85,
            },
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          }],
        },
      },
    },
  }),
  computed: {
    evaluationColumns() {
      return [
        {
          text: this.$t('Por continente'), value: 'evaluations_by_continent', align: 'left', sortable: false,
        },
        {
          text: this.$t('Por país'), value: 'evaluations_by_country', align: 'left', sortable: false,
        },
        {
          text: this.$t('Por región'), value: 'evaluations_by_region', align: 'left', sortable: false,
        },
      ]
    },
    sectionParser() {
      const parser = {
        continent: 'Por continente',
        country: 'Por país',
        region: 'Por región',
      }

      return parser
    },
    chartSeries() {
      return {
        evaluationsSeries: {
          countryChart: [],
          continentChart: [],
          regionChart: [],
        },
        schoolsSeries: {
          countryChart: [],
          continentChart: [],
          regionChart: [],
        },
      }
    },
    schoolColumns() {
      return [
        {
          text: this.$t('Por continente'), value: 'schools_by_continent', align: 'left', sortable: false,
        },
        {
          text: this.$t('Por país'), value: 'schools_by_country', align: 'left', sortable: false,
        },
        {
          text: this.$t('Por región'), value: 'schools_by_region', align: 'left', sortable: false,
        },
      ]
    },
  },
  mounted() {
    this.printer = new Printd()
    this.getEvaluationStatistics()
    this.getSchoolStatistics()
  },
  methods: {
    async getEvaluationStatistics() {
      this.evaluationLoading = true
      try {
        const response = await this.$api.getEvaluationStatistics()
        this.evaluationStatistics = response
        this.setEvaluationsChartSeries()
      } catch (e) {
        console.log(e)
      } finally {
        this.evaluationLoading = false
      }
    },

    async setEvaluationsChartSeries() {
      this.chartSeries.evaluationsSeries.countryChart = []
      this.chartSeries.evaluationsSeries.regionChart = []
      this.chartSeries.evaluationsSeries.continentChart = []
      try {
        this.chartSeries.evaluationsSeries.countryChart = this.evaluationStatistics.evaluations_by_country.map(el => el.total)

        let globalIndex = 0
        for (const property in this.evaluationStatistics.evaluations_by_region) {
          const serie = this.evaluationStatistics.evaluations_by_region[property].map(el => el.total)
          this.chartSeries.evaluationsSeries.regionChart = this.chartSeries.evaluationsSeries.regionChart.concat(serie)
          const label = this.evaluationStatistics.evaluations_by_region[property].map(el => el.region)
          this.chartOptions.evaluationsCharts.regionChart.labels = this.chartOptions.evaluationsCharts.regionChart.labels.concat(label)

          //Asignar el indice como atributo para poder luego asignar los colores en las leyendas.
          this.evaluationStatistics.evaluations_by_region[property].forEach(element => {
            element.index = globalIndex
            globalIndex++
          })
        }
        for (const property in this.evaluationStatistics.evaluations_by_continent) {
          this.chartSeries.evaluationsSeries.continentChart.push(this.evaluationStatistics.evaluations_by_continent[property])
          this.chartOptions.evaluationsCharts.continentChart.labels.push(this.continentParser(property))
        }
        this.chartOptions.evaluationsCharts.countryChart.labels = this.evaluationStatistics.evaluations_by_country.map(el => this.countryParser[el.school__region__country__name])
      } catch (e) {
        console.log(e)
      }
    },

    async setSchoolsChartSeries() {
      this.chartSeries.schoolsSeries.countryChart = []
      this.chartSeries.schoolsSeries.regionChart = []
      this.chartSeries.schoolsSeries.continentChart = []
      this.chartOptions.schoolsCharts.continentChart.labels = []
      this.chartOptions.schoolsCharts.regionChart.labels = []
      try {
        this.chartSeries.schoolsSeries.countryChart = this.schoolStatistics.schools_by_country.map(el => el.total)
        let globalIndex = 0
        for (const property in this.schoolStatistics.schools_by_region) {
          const serie = this.schoolStatistics.schools_by_region[property].map(el => el.total)
          this.chartSeries.schoolsSeries.regionChart = this.chartSeries.schoolsSeries.regionChart.concat(serie)
          const label = this.schoolStatistics.schools_by_region[property].map(el => el.region)
          this.chartOptions.schoolsCharts.regionChart.labels = this.chartOptions.schoolsCharts.regionChart.labels.concat(label)

          //Asignar el indice como atributo para poder luego asignar los colores en las leyendas.
          this.schoolStatistics.schools_by_region[property].forEach(element => {
            element.index = globalIndex
            globalIndex++
          })
        }
        for (const property in this.schoolStatistics.schools_by_continent) {
          this.chartSeries.schoolsSeries.continentChart.push(this.schoolStatistics.schools_by_continent[property])
          this.chartOptions.schoolsCharts.continentChart.labels.push(this.continentParser(property))
        }

        this.chartOptions.schoolsCharts.countryChart.labels = this.schoolStatistics.schools_by_country.map(el => this.countryParser[el.region__country__name])

      } catch (e) {
        console.log(e)
      }
    },

    async getSchoolStatistics() {
      this.schoolLoading = true
      try {
        const response = await this.$api.getSchoolStatistics()
        this.schoolStatistics = response
        this.setSchoolsChartSeries()
      } catch (e) {
        console.log(e)
      } finally {
        this.schoolLoading = false
      }
    },

    printEvaluationSection() {
      const printContainer = document.createElement('div')

      const statistics = this.$refs.evaluation_statistics.cloneNode(true)
      printContainer.appendChild(statistics)
      this.printer.print(printContainer, [this.printCss])
    },

    printSchoolSection() {
      const printContainer = document.createElement('div')

      const statistics = this.$refs.school_statistics.cloneNode(true)
      printContainer.appendChild(statistics)
      this.printer.print(printContainer, [this.printCss])
    },

    printFullSection() {
      const printContainer = document.createElement('div')
      const titleContainer = document.createElement('div')

      const title = document.createElement('h1')
      title.textContent = `${this.$t('Estadísticas')}`
      titleContainer.appendChild(title)
      printContainer.appendChild(titleContainer)

      const evaluationStatistics = this.$refs.evaluation_statistics.cloneNode(true)
      const schoolStatistics = this.$refs.school_statistics.cloneNode(true)
      printContainer.appendChild(evaluationStatistics)
      printContainer.appendChild(schoolStatistics)

      this.printer.print(printContainer, [this.printCss])
    },
    continentParser(continent) {
      if (continent === 'America') return `${this.$t('Latinoamerica y el Caribe')}`

      return continent
    },

    getLegendIndex(idx) {
      return idx % 6
    },
  },
  setup() {
    const { t } = useUtils()

    const printCss = `
          h2 {
            margin-bottom: 4px !important
          }

          .evaluation-statistics-container {
            margin-bottom: 10px !important
          }

          .statistics-subheader {
            margin-bottom: 8px !important
          }

          .no-print {
            display: none
          }

          .eval-stat-section {
            // break-after: page;
            display:flex;
            flex-direction: row;
          }

          .school-stat-section {
            // break-after: page;
            display:flex;
            flex-direction: row;
          }

          .print-labels {
            width: 300px !important;
          }

          .legend-item {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
          }

          .legend-color {
            background-color: blue;
            width: 6px;
            height: 6px;
            margin-right: 8px;
            border: 6px solid ;
            border-radius: 50%;
            }

          .legend-0 {
            border-color: #1D4F91;
          }

          .legend-1 {
            border-color: #FFB81C;
          }

          .legend-2 {
            border-color: #00BA91;
          }

          .legend-3 {
            border-color: #84239B;
          }

          .legend-4 {
            border-color: #E0004D;
          }

          .legend-5 {
            border-color: #319ee9;
          }
        `

    return {
      t,
      printCss,

      // icons
      icons: {
        mdiPrinter,
      },
    }
  },
}
</script>
